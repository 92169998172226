import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { TemplatePageTitleStrategy } from '@core/services/page-title.service';
import { provideToastr } from 'ngx-toastr';
import routes from './app.routes';

export default {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([JwtInterceptor, ErrorInterceptor])),
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    provideNativeDateAdapter(),
    provideToastr()
  ]
} as ApplicationConfig;
