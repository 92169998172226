import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UiService } from '@shared/services/ui.service';
import { filter, map, Subject, switchMap, takeUntil } from 'rxjs';
import { HeaderComponent } from './components/header/header.component';
import { NavComponent } from './components/nav/nav.component';

// $tablet-width
const SMALL_WIDTH_BREAKPOINT = 1366;

@Component({
  selector: 'pd-layout',
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    RouterOutlet,
    MatSidenavModule,
    NavComponent,
    HeaderComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  unsubscribeAll$ = new Subject<void>();

  readonly navigationEndEvents = this.router.events.pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this.unsubscribeAll$));
  isScreenSmall = this.breakpoints.observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`).pipe(map(breakpoint => breakpoint.matches));
  notifications = this.uiService.getNotification();

  constructor(
    private breakpoints: BreakpointObserver,
    private uiService: UiService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.navigationEndEvents.pipe(
      switchMap(() => this.isScreenSmall)
    ).subscribe(isScreenSmall => {
      if (isScreenSmall && this.sidenav) {
        this.sidenav.close();
      }
    });
  }

  toggleSidenav(): void {
    this.sidenav?.toggle();
  }

  unsubscribeAll(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
