import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { StorageService } from 'src/app/pages/auth/services/storage.service';
import { BrandingComponent } from '../branding/branding.component';

@Component({
  selector: 'pd-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterLink,
    MatButtonModule,
    BrandingComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Output() menuOpened = new EventEmitter<void>();

  constructor(
    private storageService: StorageService
  ) {

  }

  logout() {
    this.storageService.logout();
  }
}
