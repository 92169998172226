<mat-toolbar>
  <pd-branding />
  <div class="actions">
    <button class="pd-icon-button logout" mat-stroked-button color="primary" (click)="logout()">
      <i class="icon-logout"></i>
    </button>
    <button class="pd-icon-button burger" mat-stroked-button color="primary" (click)="menuOpened.emit()">
      <i class="icon-burger"></i>
    </button>
  </div>
</mat-toolbar>