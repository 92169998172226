export interface MenuItem {
  id?: string;
  route: string | null;
  icon: string;
  name: string;
  summary?: string;
}

export const ALL_ITEMS: MenuItem[] = [
  {
    route: '/dashboard',
    icon: 'home',
    name: 'Home'
  },
  {
    route: '/vouchers',
    icon: 'ticket',
    name: 'Vouchers List'
  },
  {
    route: '/balance-history',
    icon: 'ticket',
    name: 'Balance History'
  },
  {
    route: '/deposit',
    icon: 'dollar-square',
    name: 'Add Money'
  },
  {
    route: '/login',
    icon: 'dollar-square',
    name: 'Balance History'
  },
  {
    route: '/kyc',
    icon: 'security-user',
    name: 'Identity Verification'
  },
  {
    route: null,
    icon: 'logout-menu',
    name: 'Logout'
  },
];
