import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { TemplatePageTitleStrategy } from '@core/services/page-title.service';
import routes from './app.routes';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

export default {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([JwtInterceptor, ErrorInterceptor])),
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    provideNativeDateAdapter(),
    provideClientHydration(),
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      } }
  ]
} as ApplicationConfig;
