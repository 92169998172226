import { AsyncPipe } from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { UiService } from '@shared/services/ui.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    SpinnerComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class AppComponent {
  uiService = inject(UiService);
  protected loading = this.uiService.getLoading();

  matIconRegistry = inject(MatIconRegistry);
  domSanitizer = inject(DomSanitizer);

  constructor() {
    this.matIconRegistry.addSvgIcon('custom-close', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/custom-close.svg'));
  }
}
