import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { UiService } from '@shared/services/ui.service';
import { map } from 'rxjs';
import { AuthApiService } from '../services/auth-api.service';
import { AuthStatus, StorageService } from '../services/storage.service';

export const authGuard: CanMatchFn = () => {
  const storageService = inject(StorageService);
  const authApiService = inject(AuthApiService);
  const uiService = inject(UiService);
  const router = inject(Router);

  if (storageService.authenticationStatus === AuthStatus.pending) {
    if (storageService.token) {
      return authApiService.getAuthorization().pipe(
        map(response => {
          if (response) {
            storageService.authenticationStatus = AuthStatus.passes;
            router.navigateByUrl('/dashboard');
          } else {
            storageService.logout(false);
          }
          uiService.stopLoading();
          return !response;
        })
      );
    } else {
      storageService.logout(false);
      uiService.stopLoading();
      return true;
    }
  } else if (storageService.authenticationStatus === AuthStatus.passes) {
    router.navigateByUrl('/dashboard');
    return false;
  } else {
    return true;
  }
};