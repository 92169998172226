import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'pd-branding',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule
  ],
  templateUrl: './branding.component.html',
  styleUrl: './branding.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.side-nav-branding]': 'closeMenu'
  }
})
export class BrandingComponent {
 @Input() closeMenu = false;
 @Output() menuClosed = new EventEmitter<void>();
}
